import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youmauto.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/mauto_1.svg"
import HeaderImg from "../../assets/img/youMAUTO/headerImg.png"
import CampaignIcon from "../../assets/img/youMAUTO/CampaignIcon.svg"
import IntegrationIcon from "../../assets/img/youMAUTO/IntegrationIcon.svg"
import ContactsIcon from "../../assets/img/youMAUTO/ContactsIcon.svg"
import LandingIcon from "../../assets/img/youMAUTO/LadingIcon.svg"
import GroupImg1 from "../../assets/img/youMAUTO/LeftSide.png"
import GroupImg2 from "../../assets/img/youMAUTO/RightSide.png"
import UiIcon from "../../assets/img/youMAUTO/UiIcon.svg"
import OpenSourceIcon from "../../assets/img/youMAUTO/OpenSourceIcon.svg"
import ThirdPartyIcon from "../../assets/img/youMAUTO/ThirdPartyIcon.svg"
import HostIcon from "../../assets/img/youMAUTO/HostIcon.svg"
import SingleForm from "../../components/singleForm"

const YouMAPage = () => {
  return (
    <div>
      <SEO title="Youmauto" keywords={[`youpal`, `youpal group`, `youMauto`]} />
      <div className="youMAUTOMain products">
        <section className="container">
          <div className="row">
            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                  Invest Once And Benefit Forever with Marketing Automation
                </h2>
              </div>
            </div>
            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol">
                There is an old saying in the marketing industry: "The advertising budget of which results you can't measure is wasted."
              </p>
            </div>
            <div className="col-12 order-4 order-md-3">
            <p className="internalNarrowCol">
              Digital marketing has been prevailing over traditional marketing recently because of its extensive tools and features that provide advanced analysis opportunities.
            </p>
            </div>
            <div className="col-12 order-5 order-md-4">
            <p className="internalNarrowCol">
              Youmauto is your digital marketing assistant with the potential of replacing tens of employees, thus shrinking your fixed costs. Interested in more? You should be!

            </p>
            </div>
            <div className="col-12 order-2 order-md-5">
              <img className="headerImg" src={HeaderImg} />
            </div>

          </div>
        </section>

        <section className="container">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
              Automate. Optimise. Sit back. Earn.
            </h3>
            <p className="internalNarrowCol">
            The problem with marketing in today's world is having dozens of various channels with their distinctive characteristics. Analysing data acquired from different sources like your website, Google Ads, social media pages, etc. turns into one of the most challenging tasks. 

            </p>
            <p className="internalNarrowCol">
              
Additionally, setting up ads, campaigns, and creatives for each new channel also require lots of time and energy. And you physically can't have it enough, right?

            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={CampaignIcon} />
              </div>
              <h4>Drip Programmes & Campaign Management</h4>
              <p>
              One of Youmauto's best-known features is its user-friendly, functional and flexible drip flow program creation and management feature. In each campaign you identify triggers and actions, your leads will go through in their customer journey.

              </p>
              <p>
                
Automation will move your leads through the sales funnel stages you've already set up and you'll be able to watch and analyse the outcome.

              </p>
            </div>
            <div className="col">
              <div>
                <img src={IntegrationIcon} />
              </div>
              <h4>Easy Integration of Social Media Pages</h4>
              <p>
              Each social media network has its own powerful sides and advantages. However, they are much more effective when combined. Integrating your social media pages with Youmauto will gather all your social media data in one place.

              </p>
              <p>
                
Analysing and using this data will open new doors in front of you. For example, you'll be able to add the people who engaged with your business on Facebook to your Google Ads audiences and reach them anywhere  they' surf the web.

              </p>
            </div>
          </div>
          <div className="row mt-0 mt-md-5">
            <div className="col">
              <div>
                <img src={ContactsIcon} />
              </div>
              <h4>Track & Nurture Contacts</h4>
              <p>
              Youmauto contact management features provide companies with all the means to build their marketing campaigns for each part of the customer journey. Tracking contacts let businesses store some valuable information such as page views, 
average time spent on site and interest areas.

              </p>
              <p>
                
Advanced API integration makes it possible to create custom fields for specific information that businesses want to track and store 
              </p>
            </div>
            <div className="col">
              <div>
                <img src={LandingIcon} />
              </div>
              <h4>Landing Page Builder</h4>
              <p>
              Landing pages are the very first step in generating new leads. Youmauto landing page builder comes with everything to create stunning and converting pages.

              </p>
              <p>
                
When you create a page through the builder, you're able to track many on-site actions and personalize them according to the visitor. The more specialized your landing page is, the more leads it converts at the end of the day.

              </p>
            </div>
          </div>
        </section>

        <section className="container internalSection internalSection1">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg sideImgR" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left" style={{ textTransform: 'none' }}>
                  Youmauto
                </div>
                <h3 className="text-left">How Does It Work?</h3>
                <p>
                Let's imagine John is someone who is in your target audience. Thanks to your SEO efforts, John finds your website when he searches online. Your title attracts him, and he decides to visit your website and see what you have for him.

                </p>
                <p>
                  
As you have already set up Youmauto, it tracks John from the moment he landed on one of your web pages. For now, Youmauto tracks him as a visitor and collects his IP address, location info, and some other basic information (following Data Privacy compliance).

                </p>
                <p>
But how do you use this data? Simple. Now John is in your remarketing audience, which you target your ads to. When John clicks one of these ads and fills in a form (sign-up, registration, etc.), he turns into a lead.

                </p>
                <p>
Knowing John's email and other contact information allows you to utilize extensive knowledge of his usage of Google, Facebook, Instagram etc. You can now segment him as you want, assign lead points to him, add him to various marketing automation journeys, and so on.

                </p>
                <p>
And all of these will eventually result in Jacob being your constant and loyal customer who will skyrocket your revenue alongside others.

                </p>
                <div className="buttonArea">
            <Link to="/products/request-demo">
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">Features</div>
              <h3>Why Youmauto?</h3>
              <div className="row mt-md-5 mt-2">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={UiIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Intuitive and user-friendly interface</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={HostIcon} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Software as a Service</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={ThirdPartyIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>
                        Integration options with hundreds of third-party apps
                        and software
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={OpenSourceIcon} />
                    </div>
                    <div className="col sideContent">
                      <h4>Full customisation features</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection internalSection2">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <h3>Your Saviour is Marketing Automation</h3>
                <p>
                It helps you to know your target audience better than themselves, hear their wishes, and earn their ultimate loyalty by addressing the matters they care about the most. Marketing automation lets you create a different image of your brand in each of your customers' eyes. Hard to believe, but it's real!

                </p>
                <p>
Once you set up Youmauto, it tracks all of your customers, leads, and even visitors and their actions related to your business. It all happens automatically through the use of website pages, email and ad campaigns, social media tracking, and integration with your CRM and other third-party software.

                </p>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YouMAPage
